// Mixins: transation delay 

@mixin transation_delay () {

	-webkit-transition: all 0.7s ease;
	 -moz-transition: all 0.7s ease;
	  -ms-transition: all 0.7s ease;
   	   -o-transition: all 0.7s ease;
          transition: all 0.7s ease;
}

// hover scale 

@mixin scale () {

	-webkit-transform: scale(1.05);
    	-ms-transform: scale(1.05);
    		transform: scale(1.05);
}


// Mixins : Border Radius

@mixin border_radius ( $radius ) {

	-webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;

}


// Mixins : drop shadow 


@mixin drop_shadow () {

	box-shadow: 0 2px 6px rgba(0,0,0,.2);
	 -webkit-box-shadow: 0 2px 6px rgba(0,0,0,.2);
	   -o-box-shadow: 0 2px 6px rgba(0,0,0,.2);;
	     -ms-box-shadow: 0 2px 6px rgba(0,0,0,.2);
	        -moz-box-shadow: 0 2px 6px rgba(0,0,0,.2);
}


@mixin drop_shadow_alt () {

	box-shadow: 0px 2px 30px -5px rgba(0,0,0,0.1);
	  -webkit-box-shadow: 0px 2px 30px -5px rgba(0,0,0,0.1);
	    -moz-box-shadow: 0px 2px 30px -5px rgba(0,0,0,0.1);
	      -ms-box-shadow: 0px 2px 30px -5px rgba(0,0,0,0.1);
	        -o-box-shadow: 0px 2px 30px -5px rgba(0,0,0,0.1);
    

}



//  Mixins : Position absolute top align 


@mixin position_absolute ( $top, $bottom, $left, $right ) {
	
	 position: absolute;
	 z-index: 10;
}


// Mixins : Black mask 


@mixin mask () {

	 position: absolute;
	 z-index:9;
	 top:0;
	 bottom:0;
	 left:0;
	 right:0;
	 background:rgba(0,0,0,0.2);
	 @include transation_delay();
}



// Minixs : Lazy load images 


@mixin lazy () {
	
	
	-webkit-transition: opacity 1500ms ease-in-out;
           -moz-transition: opacity 1500ms ease-in-out;
             -o-transition: opacity 1500ms ease-in-out;
                transition: opacity 1500ms ease-in-out;
                 max-width: 100%;
                   opacity: 0;
}


// Mixis for custom row ( Flex row)


@mixin flex_row () {

	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

@mixin flex_row_alt () {

	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

@mixin flex_col ( $width ) {

	-webkit-box-flex: 0;
    -ms-flex: 0 0 $width;
    flex: 0 0 $width;
    max-width: $width;
}


