
// Define Few colors

$facebook:#4267B2;

$twitter:#1DA1F2;

$instagram:#8A33A4;

$googleplus:#DC4C40;

$vk:#507299;

$pinterest:#BD081C;

$reddit:#CEE3F8;

$youtube:#FF0000;

$snapchat:#FFD600;

$linkedin:#0274B3;

$vimeo:#1AB7EA;

$black:#000;

$white:#fff;