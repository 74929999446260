/* Header Common */

header.cm-header-style-one {
    
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

header .widget {

    background:transparent;
    padding:0;
}

header .widget img {

    padding:0;
}

.top-header {

    padding: 10px 0;
    @include drop_shadow_alt();
    background-color: #ffffff;
}

.top-header-left ul,
.top-header-social-links ul {

    list-style:none;
    margin: 0px;
    padding: 0px;
}

.top-header-left ul li {

    display:inline-block;
    margin-right:10px;
    margin-bottom: 0px;
}

.top-header-left ul li:last-child {

   margin-right:0;
}

.top-header-left ul li a {
   
   display:inline-block;
   line-height:30px;
   font-size: 15px;
}

.top-header-left ul li a span.menu-item-description {
    
    display: none;
}

.top-header-social-links ul {
        
    text-align:right;
}

.logo-container {

    padding: 30px 0;
}

.site-logo {
    
    margin-bottom: 0px;
}

header .site-title {
    
    font-family:$primary_font_family; // Roboto
    font-size: 28px;
    font-weight:bold;
    line-height:1.2;
    margin-bottom:0;
}

header .site-description {

  font-size: 14px;
}

header .advertisement-area {

     text-align:right;
     display:flex;
     flex-wrap: wrap;
     flex-direction: row;
     justify-content: flex-end;
}

header .advertisement-area .widget_text .textwidget img {
    
    display:inline-block;
    padding:0;
    margin:0;
}


header.general-header #sticky-wrapper nav.main-navigation {
    
    z-index: 3 !important;
    position: relative;
}

.primary-navigation ul li a {

    position:relative;   
}

.primary-navigation ul li.current_page_item {
    position: relative;
}

.primary-navigation > ul > li.home-btn {
    
    background: $secondary_color;
}

.primary-navigation.dark li.home-btn a:hover {

    color:$white;
}

.primary-navigation li.primarynav_search_icon {

   float:right;
}

.header-search-container {

   position:relative;
   display:none;
}

.header-search-container .search-form-entry {

   position:absolute;
   top:0;
   right:0;
   z-index:1000;
   width:400px;
   max-width:100%;
   background: $white;
   padding: 20px;
   border-top: 3px solid $secondary_color;
   overflow: auto;
   animation: 0.3s 0s fadeInUp both;
   @include drop_shadow();
}


/* Second Header */

.cm_header-five .top-header {
    
    position: relative;
    padding: 0;
    box-shadow: none;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.cm_header-five .top-header .logo-container {
    
    position: relative;
    z-index: 10;
    text-align: center;
    padding: 70px 0;
    margin-bottom: 30px;
}


/* Header Social icons */

ul.social-icons li {
    
    margin-bottom: 0px;
    display: inline-block;
}

ul.social-icons li a {
    
    display: inline-block;
    font-size: 0;
    height: 0;
    margin-right: 0;
    line-height: 30px;
    width: 30px;
    height: 30px;
    text-align: center;
}

ul.social-icons li a[href*=".com"]::before {
    
    font-family: 'FontAwesome';
    color: $primary_color;
    @include transation_delay();
    font-size: 14px;
}

ul.social-icons li a[href*="fb.com"]::before,
ul.social-icons li a[href*="messenger.com"]::before,
ul.social-icons li a[href*="facebook.com"]::before {

    content: "\f09a";
    color:$facebook;
}

ul.social-icons li a[href*="twitter.com"]::before {

    content: "\f099";
    color:$twitter;
}

ul.social-icons li a[href*="instagram.com"]::before {

    content: "\f16d";
    color:$instagram;
}

ul.social-icons li a[href*="youtube.com"]::before {

    content: "\f16a";
    color:$youtube;
}

ul.social-icons li a[href*="vk.com"]::before {

    content: "\f189";
    color:$vk;
}

ul.social-icons li a[href*="linkedin.com"]::before {

    content: "\f0e1";
    color:$linkedin;
}

ul.social-icons li a[href*="vimeo.com"]::before {

    content: "\f27d";
    color: $vimeo;
}

ul.social-icons li a[href*="pinterest.com"]::before {

    content: "\f0d2";
    color: $pinterest;
}

ul.social-icons li a[href*=".com"]:hover::before {

    color: $secondary_color;
}