
.page-links .post-page-numbers,
body.single .the_content .page-links .post-page-numbers {

    color:$primary_color;
    text-decoration: none;
}

.pagination .page-numbers,
.page-links .post-page-numbers {

    display: inline-block;
    font-size:15px;
    margin-right: 10px;
    border-radius:0;
    text-align: center;
    border: 1px solid $border_color;
    line-height: 45px;
    width: 45px;
    height: 45px;
    margin-bottom: 10px;
}

.pagination .page-numbers:hover,
.page-links .post-page-numbers:hover {

    border:1px solid $secondary_color;
}

.pagination .page-numbers:last-child,
.page-links .post-page-numbers:last-child {

    margin-right:0;
}

.pagination .page-numbers.current,
.page-links .post-page-numbers.current,
body.single .the_content .page-links .post-page-numbers.current {

    background: $secondary_color;
    color: $white;
    border: none;
}

.pagination .page-numbers.current:hover,
.page-links .post-page-numbers.current:hover {
    
    color: $white;
}

.pagination .page-numbers.next,
.pagination .page-numbers.prev {

    width: auto;
    padding: 0 15px;
}