/** Banner Common Styles **/

.banner-area {
    
    margin-top: 30px;
}

.cm_banner .entry_cats {
    
    margin-bottom: 7px;
}

.cm_banner .post_meta {
    
    margin-top: 7px;
}

.cm_banner .post_title h2 {

    font-size: 24px;   
}

.cm_banner .post_title h2 a {

   color: $white;       
}

.cm_banner .post_title h2 a:hover {

   color: $secondary_color;     
}

.cm_banner .post_meta li a  {

   color: $auth_date_cmnt_color_bg;  
}

.cm_banner .post_meta li a:hover {

   color: $secondary_color; 
   @include transation_delay();    
}

.cm_banner .lazy-thumb {

    padding-bottom:0;
}



/** Banner Layout Five **/

.cm_banner-five .post_thumb {

    position: relative;
} 

.cm_banner-five .post_thumb:hover mask {
        
    background-color: rgba(0,0,0,0.6);
}

.cm_banner-five .card {

    margin-bottom:0;
}

/* Left Side */ 

.cm_banner-five .gutter-left {

    padding-right: 5px;
    position: relative;
}

.cm_banner-five .gutter-left .post_thumb {

    height: 420px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;    
}

.cm_banner-five .gutter-left .post-holder {

    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
    background-image: linear-gradient( 180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 40%, rgba(0, 0, 0, 1) 100% );
}

.cm_banner-carousel-five {
    
    position: relative;
}

.cm_banner-carousel-five .owl-nav {

    @include position_absolute(15px, inherit, inherit, 10px);
    top: 20px;
    right: 20px;
    transform: none;
}

.cm_banner-carousel-five .owl-nav button.owl-prev,
.cm_banner-carousel-five .owl-nav button.owl-next {
    
    line-height: 35px;
    width: 35px;
    height: 35px;
    font-size: 18px;
}

/* Right Side */

.cm_banner-five .gutter-right {

    padding-left: 5px;
}

.cm_banner-five .gutter-right .post_thumb {

    height: 205px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.cm_banner-five .gutter-right .post-holder {

    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
    background-image: linear-gradient( 180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 40%, rgba(0, 0, 0, 1) 100% );   
}

.cm_banner-five .gutter-right .post_title h2 {
    
    font-size: 16px;
}


.cm_banner-five .gutter-right .col:first-child,
.cm_banner-five .gutter-right .col:nth-child(2) {

    margin-bottom: 10px;
}

.cm_banner-five .gutter-right .col:first-child,
.cm_banner-five .gutter-right .col:nth-child(3) {

    width: 50%;
    padding-right: 5px;
}

.cm_banner-five .gutter-right .col:nth-child(2),
.cm_banner-five .gutter-right .col:nth-child(4) {

    padding-right: 15px;
    padding-left: 5px;
}

.cm_banner-five .gutter-right .col:nth-child(3),
.cm_banner-five .gutter-right .col:nth-child(4) {

    margin-bottom: 0px;
}

.cm_banner-five .gutter-right .col:nth-child(3) {

    padding-left: 15px;
}

.cm_banner-five .gutter-right .right-content-holder ul.post-categories li a {

    font-size: 12px;
}