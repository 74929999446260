.nothing-found {
    
    margin-bottom: 60px;
}

.nothing-found .section-title h1 {

    font-size: 26px;
}

.nothing-found .error_foot p {

    margin-bottom:30px;
}