.archive-description {
    
    margin-top: 30px;
}

.cm_archive_page .section-title h1,
.cm_search_page .section-title h1,
.cm_not_found_page .section-title h1 {
    
    display: block;
    font-size: 26px;
    background: transparent;
    font-weight: bold;
    color: $primary_color;
    margin-bottom: 0;
}
