// Lazyload keyframes

@-webkit-keyframes scaleout {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
}

@keyframes scaleout {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
}


@keyframes shine-lines {
    0% {
        background-position: -100px;
    }
    40%,
    100% {
        background-position: 140px;
    }
}

@keyframes background-loading {
  from {
    background-position: -10000% 0, 0 0
  }
  to {
    background-position: 10000% 0, 0 0
  }
}
