
// Variables for font family

$primary_font_family 			: 'Roboto', sans-serif;
$secondary_font_family 			: 'Muli', sans-serif;
$system_font 					: -apple-system, BlinkMacSystemFont, "Noto Sans", "system-ui", "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";


// Variables line-height 

$body_line_height				: 1.6;
$body_line_height_mobile_device : 1.4;	
$body_heading_line_height		: 1.2;  // Refers to h1,h2,h3,h4,h5,h6 


// Variables for font weight

$body_font_weight				: normal;
$body_heading_font_weight		: bold;


// Variables for font sizes

$body_font_size					: 1rem; 

// letter spacing 

$body_letter_spacing			:0;
$heading_letter_spacing			:0;


// Size variations for headings  


$typo_big 						: 24px;
$typo_medium					: 20px;
$typo_small						: 16px;




